export type InputErrorProps = {
  message?: string;
  className?: string;
};
export default function InputError({
  message,
  className = '',
  ...props
}: InputErrorProps) {
  return message ? (
    <p
      {...props}
      className={'text-sm text-red-600 dark:text-red-400 ' + className}
    >
      {message}
    </p>
  ) : null;
}
